<template>
    <div>
        <b-modal
            id="modal-link-invoice"
            no-close-on-backdrop
            size="xl"
            :title="i18nT(`Select invoice for credit note`)"
        >
            <!-- TRANSACTION DETAILS -->

            <b-row>
                <!-- LEFT SIDE -->
                <b-col cols="6">

                    <b-row class="">
                        <!-- FILTERS -->
                        <b-col cols="12" >
                            <b-row>

                                <!-- FILTERS -->
                                <b-col
                                    cols="12"
                                    md="12"
                                >
                                    <h4 v-if="documentType == 1" class="mb-2">{{i18nT(`Select income document`)}}</h4>
                                    <h4 v-if="documentType == 2" class="mb-2">{{i18nT(`Select expense document`)}}</h4>
                                    <b-form-group>
                                        <div
                                            role="group"
                                            class="input-group input-group-merge"
                                        >
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">
                                                    <feather-icon icon="SearchIcon"/>
                                                </div>
                                            </div>
                                            <b-form-input
                                                v-model.lazy="searchQuery"
                                                :placeholder="i18nT(`Search by document number`)"
                                                type="text"
                                                class="d-inline-block"
                                            />
                                        </div>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col
                                    cols="12"
                                    md="6"
                                >
                                    <b-form-group
                                        :label="i18nT(`Client`)"
                                        label-for="assignee"
                                    >
                                        <v-select
                                            id="assignee"
                                            :clearable="false"
                                            v-model="vendor"
                                            :options="vendorList"
                                            label="text"
                                        />
                                    </b-form-group>
                                </b-col>

                                <b-col
                                    cols="12"
                                    md="6"
                                >
                                    <b-form-group
                                        :label="i18nT(`Category`)"
                                        label-for="documentGroup"
                                    >
                                        <v-select
                                            id="documentGroup"
                                            v-model="category"
                                            :options="paymentCategoryList"
                                            :reduce="option => parseInt(option.value)"
                                            :clearable="false"
                                            label="text"
                                        />
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12">
                                    <b-form-group
                                        :label="i18nT(`Document from period`)"
                                        label-for="paymentType"
                                    >
                                        <b-input-group>
                                            <b-input-group-prepend>
                                                <b-button
                                                    variant="primary"
                                                    @click.stop="inputClick"
                                                >
                                                    <font-awesome-icon  :icon="['fas', 'calendar-alt']" />
                                                </b-button>
                                            </b-input-group-prepend>
                                            <VueCtkDateTimePicker
                                                :id="'InvoiceDateRange'"
                                                class=""
                                                :range="true"
                                                :min-date="minDate"
                                                :disabled="false"
                                                v-model="reportRange"
                                                :only-date="true"
                                                :only-time="false"
                                                :format="`YYYY-MM-DD`"
                                                :formatted="dateFormat"
                                                :color="`#5a5aff`"
                                                :label="i18nT(`Select date`)"
                                                :button-now-translation="i18nT(`Today`)"
                                                :first-day-of-week="1"
                                                :no-button="true"
                                                :auto-close="true"
                                                :locale="localeCtk"
                                                :ref="`invoiceRangePicker`"
                                            />
                                        </b-input-group>

                                    </b-form-group>
                                </b-col>

                            </b-row>


                        </b-col>

                        <b-col cols="12">
                            <b-table
                                ref="refDocumentListTable"
                                id="invoiceListTable"
                                class="position-relative mt-3"
                                :items="fetchDocuments"
                                responsive
                                :fields="tableColumns"
                                primary-key="id"
                                :sort-by.sync="sortBy"
                                show-empty
                                :empty-html="emptyHtml"
                                :sort-desc.sync="isSortDirDesc"
                                @refreshed="onTableRefresh"
                                @row-clicked="onRowClicked"
                                :tbody-tr-class="rowClass"
                                v-model="currentItems"
                            >
                                <template #cell(invoice_date)="data">
                                    {{ data.item.InvoiceDate | date }}
                                </template>

                                <template #cell(type)="data">
                                    <p>
                                        <span class="d-block font-weight-bolder">{{ data.item.Prefix}}{{ data.item.InvoiceNumber}}</span>
                                        <span class="d-block font-small-3">{{ getCategoryName(data.item.Category) }}</span>
                                    </p>
                                    <p class="font-small-3 mb-0">{{ truncateStr(data.item.InternalComments, 12) }}</p>
                                </template>

                                <template #cell(total_amount)="data">
                                <span :class="data.item.Type == 1 ? 'text-success' : 'text-danger'">
                                    {{data.item.Type == 1 ? '+' : '-'}}{{ {amount: data.item.TotalAmount, currency: data.item.Currency } | currency }}
                                </span>
                                </template>

                                <template #cell(vendor)="data">
                                    <span v-if="data.item.VendorObj">{{ data.item.VendorObj.Label }}</span>
                                </template>

                                <template #cell(PaidStatus)="data">
                                    <b-badge
                                        :variant="statusLabelClass(data.item.PaidStatus)"
                                        class="mr-1 badge-pill">
                                        {{statusLabel(data.item.PaidStatus)}}
                                    </b-badge>
                                </template>

                            </b-table>

                            <b-row>
                                <b-col
                                    cols="12"
                                    sm="6"
                                    class="
                                    d-flex
                                    align-items-center
                                    justify-content-center justify-content-sm-start
                                  "
                                >

                                <span class="text-muted">{{ i18nT(`Showing`) }}
                            {{ dataMeta.from }} {{ i18nT(`to`) }}
                            {{ dataMeta.to }} {{ i18nT(`of`) }} {{ dataMeta.of }}
                            {{ i18nT(`entries`) }}</span>
                                </b-col>
                                <!-- Pagination -->
                                <b-col
                                    cols="12"
                                    sm="6"
                                    class="
                                d-flex
                                align-items-center
                                justify-content-center justify-content-sm-end
                              "
                                >
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="totalDocuments"
                                        :per-page="perPage"
                                        first-number
                                        last-number
                                        class="mb-0 mt-1 mt-sm-0"
                                        prev-class="prev-item"
                                        next-class="next-item"
                                    >
                                        <template #prev-text>
                                            <feather-icon
                                                icon="ChevronLeftIcon"
                                                size="18"
                                            />
                                        </template>
                                        <template #next-text>
                                            <feather-icon
                                                icon="ChevronRightIcon"
                                                size="18"
                                            />
                                        </template>
                                    </b-pagination>
                                </b-col>
                            </b-row>
                        </b-col>

                    </b-row>
                </b-col>

                <!-- RIGHT SIDE -->
                <b-col cols="6">
                    <transition-group name="fade" mode="out-in" class="h-100">
                        <div v-if="!selectedRow.Id" :key="`no-row`" class="d-flex justify-content-center align-items-center h-100">
                            <div class="text-center pt-2" >
                                <h4>{{i18nT(`Select a document`)}}</h4>
                                <p>{{i18nT(`Pick an item from the list on the left to link the credit note`)}}</p>
                                <div class="text-center pt-2" v-html="emptyIframeHtml" :key="`no-payments`"></div>
                            </div>
                        </div>

                        <div class="frame-wrapper" v-else :key="`has-row`">
                            <h4 class="mb-2">{{i18nT(`Document preview`)}}</h4>
                            <iframe class="invoice-preview-frame" :src="selectedRow.IframePublicUrl" width="100%" frameborder="none"></iframe>
                        </div>
                    </transition-group>
                </b-col>
            </b-row>



            <validation-observer ref="createInvoice">

                <!-- Invoice list -->
                <b-row>

                </b-row>


            </validation-observer>


            <!-- Modal Footer -->
            <template #modal-footer>
                <div class="d-flex justify-content-end">
                    <b-button
                        variant="outline-secondary"
                        class="mr-2"
                        right
                        @click="cancel"
                    >
                        {{i18nT(`Cancel`)}}
                    </b-button>

                    <b-button
                        variant="primary"
                        right
                        @click="linkToPayment()"
                        :disabled="!canSave"
                    >
                        {{i18nT(`Select`)}}
                    </b-button>
                </div>
            </template>
        </b-modal>

    </div>


</template>

<script>

import {
    //BCard,
    BRow,
    BCol,
    BButton,
    BModal,
    BTable,
    BBadge,
    VBTooltip,
    BFormGroup,
    //BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    //BFormRadio,
    BPagination,
    //BFormCheckbox
} from 'bootstrap-vue'
import {ValidationObserver} from 'vee-validate'
// import {required, email} from '@validations'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAuth from "@/auth/useAuth";
import useAbrigedFinanceList from "@/views/accounting/Transactions/useAbrigedFinanceList"

import {avatarText} from "@core/utils/filter";
import {dictToSelectArray} from "@core/utils/utils";
import vSelect from "vue-select";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import moment from "moment";

export default {
    name: "TransactionToFinance",
    components: {
        //BFormCheckbox,
        BPagination,
        BFormGroup,
        //BFormSelect,
        //BCard,
        BRow,
        BCol,
        BButton,
        BModal,
        BTable,
        BBadge,
        BFormInput,

        BInputGroup,
        BInputGroupPrepend,
        vSelect,
        ValidationObserver,
        VueCtkDateTimePicker
    },
    directives: {
        'b-tooltip': VBTooltip
    },


    data() {
        return {
            curLocale: this.$i18n.locale,
            documentType: 1,
            selectedItems: [],
            currentItems: [],

            selectedRow: {},
            availablePayments: [],
            PaymentTypeDict: [],
            selectedRowIndex: null,

            vendorList: [],
            paymentCategoryList: [],

            paymentItemsFields: [
                { key: 'checkbox', label: this.i18nT(``), thClass: 'tbl-chk', tdClass: 'tbl-chk' },
                { key: 'CreatedAt', label: this.i18nT(`Date`), sortable: true },
                { key: 'Amount', label: this.i18nT(`Amount`), sortable: true },
                { key: 'PaymentType', label: this.i18nT(`Via`), sortable: true }, //
                { key: 'Description', label: this.i18nT(`Description`), sortable: true },
            ],

            reportRange: null,

            vatPercent: 0,
            invoiceNumber: '',
            lastInvoiceNumber: '',

            saveAndEdit: false,
        }
    },
    computed: {
        hasNewRecord(){
            return this.availablePayments.find(item => item.IsNew);
        },
        canSave(){
            return this.selectedItems.length ? true : false;
        },
        dateFormat() {
            return useAuth.getDateFormat() !== 'undefined'
                ? useAuth.getDateFormat()
                : 'DD/MM/YYYY'
        },
        localeCtk() {
            return useAuth.getLocale() !== 'undefined'
                ? useAuth.getLocale()
                : 'en'
        },
        minDate() {
            return moment('2023-01-01').format();
        },
        emptyHtml() {
            return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>" + this.i18nT(`No matching records found`) + "</h4>";
        },
        emptyIframeHtml() {
            return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>" + this.i18nT(`No document selected yet`) + "</h4>";
        },
    },
    methods: {
        resetState(){
          this.selectedRow = {};
          this.selectedItems = [];
        },
        paymentTypeIcon(type) {
            switch (type) {
                case 'cash':
                    return 'money-bill-alt'
                case 'sepa_debit':
                    return 'piggy-bank'
                case 'card':
                    return 'credit-card'
                case 'bank':
                    return 'university'
                default:
                    return 'money-bill-alt'
            }
        },
        getCategoryName(typeId) {
            const invoiceType = this.paymentCategoryList.find(type => type.value == typeId)
            return invoiceType ? invoiceType.text : ''
        },
        statusLabelClass(status) {
            return {
                'paid': 'success',
                'partial': 'warning',
                'not_paid': 'light-dark',
                'overdue': 'danger',
                'overpaid': 'info',
                'void': 'dark'
            }[status]
        },
        statusLabel(status){
            return {
                'paid': this.i18nT(`Paid`),
                'partial': this.i18nT(`Partial`),
                'not_paid': this.i18nT(`Not paid`),
                'void': this.i18nT(`Void`),
                'overpaid': this.i18nT(`Overpaid`),
                'overdue': this.i18nT(`Overdue`)
            }[status]
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        rowClass(item) {
            if(item){
                return item.activeRow ? 'active-row' : ''
            }else {
                return '';
            }

        },
        onRowClicked(item, index) {
            let self = this;
            this.documents.forEach((item, itemIndex) => {
                self.documents[itemIndex].activeRow = false;
            });
            item.activeRow = true;
            this.selectedRow = item;
            this.selectedItems = [];
            this.availablePayments = [...item.InvoicePayments];
            this.selectedRowIndex = index;
            this.selectedItems = [`0`];
        },
        addNewRecord(){
        },
        cancel() {
            this.resetForm();
            this.$refs.createInvoice.reset();
            this.$bvModal.hide('modal-link-invoice')
        },
        resetForm() {
            this.vendorId = null;
            this.paymentCategory = 0;
            this.paymentCategoryId = null;
            this.vatPercent = 0;
            this.invoiceNumber = '';
        },
        linkToPayment() {
            this.$emit('selected', this.selectedRow);
            this.$bvModal.hide('modal-link-invoice')
        },

    },
    setup() {
        const {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            statusFilter,
            invoiceType,
            category,
            assignedTo,
            vendor,
            paymentType,
            documents,
            documentFilter,
            startDate,
            endDate,
            refetchData
        } = useAbrigedFinanceList(true)

        return {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            statusFilter,
            invoiceType,
            category,
            assignedTo,
            vendor,
            paymentType,
            startDate,
            endDate,
            avatarText,
            documents,
            documentFilter,
            refetchData
        }
    },
    created() {
        this.$http.get(`system/invoicePaymentTypes`).then((resp) => {
            this.PaymentTypeDict = resp.data.data;
        });
        this.$http.get(`invoices/categories?type=1`).then(({data}) => {

            let validCategories = data.data.filter(category => {
                return category.RecordType == 1;
            });

            this.paymentCategoryList = validCategories.map(category => {
                return {
                    value: category.Id,
                    text: category.Label,
                    fullData: category
                }
            })

            this.category = this.paymentCategoryList[0].value;
        })

        let filterString = '';

        if(this.documentType == 1){
            filterString = '?invoicing=1';
        }

        this.invoiceType = { value: this.documentType, text: '' } ;

        this.$http.get(`vendors/vendorsList`+filterString).then(({data}) => {
            this.vendorList = dictToSelectArray(data.data)
            this.vendorList.unshift({
                value: -1,
                text: this.i18nT(`All`)
            })
        })

        let curWorkspace = useAuth.getCurWorkspace();
        this.vatPercent = curWorkspace.MainVatPercent;
    },
    watch: {
        documentType(val){
            this.invoiceType = { value: val, text: '' } ;
        },
        documents(){
            this.availablePayments = [];
            this.selectedRow = {};
            this.selectedItems = [];
        },
        reportRange(newRange) {

            if(newRange) {
                if(newRange.start) {
                    this.startDate = moment(newRange.start).format('YYYY-MM-DD');
                }
                if (newRange.end) {
                    this.endDate = moment(newRange.end).format('YYYY-MM-DD');
                }
            } else {
                this.startDate = '';
                this.endDate = '';
            }

        },
    }
}
</script>

<style>
    div.date-time-picker {
        width: calc(100% - 56px);
    }

    .date-time-picker input.field-input {
        padding-right: 5px;
    }

    .ctk-date-time-picker__header,
    .datetimepicker .custom-button,
    .datetimepicker span.custom-button-content,
    .datepicker-buttons-container .datepicker-button.now .datepicker-button-content
    {
        /* Change the header text color */
        color: #5a5aff !important;
        fill: #5a5aff !important;
    }


    #modal-link-transaction___BV_modal_content_ div.table-responsive {
        min-height: 200px;
    }


    #invoiceListTable.table th, #invoiceListTable.table td,
    #paymentsListTable.table th, #paymentsListTable.table td{
        padding: 0.5em;
        font-size: 0.9em;
    }

    #invoiceListTable tr {
        transition: all 0.3s ease;
    }

    #invoiceListTable tr:hover {
        cursor: pointer;
        background-color: rgba(90, 90, 255, 0.1);
    }

    #invoiceListTable tr.active-row {
        background-color: rgba(90, 90, 255, 0.1);
    }

    .invoice-preview-frame {
        height: 120%;
        border: none;
        scale: 0.8;
        margin-top: -95px;
        width: 120%;
        margin-left: -10%;
    }

    .frame-wrapper {
        height: 100%;
        overflow: hidden;
    }

    .frame-wrapper iframe {

    }

</style>


<style scoped lang="scss">
    .max-w-200 {
        max-width: 200px;
    }
    .plain-modal {
        background-color: var(--light-gray);
    }

    .discountIcon {
        position: relative;
        margin: 0 auto;
        margin-top: calc(50% - 1px);
    }

    .section-title {
        font-size: 11px;
    }

    .document-name {
        display: inline;
        line-height: 32px;
    }

    .document-actions-bar {
        float: right;
    }
</style>

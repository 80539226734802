import { ref, watch, computed } from '@vue/composition-api';
import axios from '@axios';
import useAuth from '@/auth/useAuth';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import {BButton, BCol, BFormGroup, BInputGroup, BInputGroupPrepend} from "bootstrap-vue";
const { i18n } = useI18nUtils();
function i18nT(str){
    return i18n(str)
  };
export default function useDocumentList(isTemplate) {
  const refDocumentListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'invoice_date', label: i18nT(`Date`), sortable: true },
    { key: 'total_amount', label: i18nT(`Amount`), sortable: true },
    { key: 'type', label: i18nT(`Description`), sortable: true, thClass: 'pl-1', tdClass: 'pl-1' },
    { key: 'vendor', label: i18nT(`Vendor`), sortable: true },
    { key: 'PaidStatus', label: i18nT(`Status`), sortable: true },
  ];
  const perPage = ref(5);
  const totalDocuments = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [5, 10];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);
  const invoiceType = ref({ value: -1, text: i18nT('All') });
  const category = ref({ value: -1, text: i18nT('All') });
  const vendor = ref({ value: 0, text: i18nT('All') });
  const paymentType = ref({ value: -1, text: i18nT('All') });
  const startDate = ref('');
  const endDate = ref('');
  const documents = ref([]);
  const documentFilter = ref('');

  const dataMeta = computed(() => {
    const localItemsCount = refDocumentListTable.value
      ? refDocumentListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDocuments.value,
    };
  });


  const refetchData = () => {
    if(refDocumentListTable && refDocumentListTable.value){
      refDocumentListTable.value.refresh();
    }
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      invoiceType,
      category,
      documentFilter,
      vendor,
      startDate,
      endDate,
      paymentType,
      isSortDirDesc,
      sortBy,
    ],
    () => {
      refetchData();
    }
  );

  const fetchDocuments = (ctx, callback) => {
    const params = {
      page: currentPage.value,
      show_per_page: perPage.value,
      sort_by: sortBy.value,
      sort_order: isSortDirDesc.value ? 'DESC' : 'ASC',
      search: searchQuery.value,
      start_date: startDate.value,
      end_date: endDate.value,
    };

    if (isTemplate) {
      params.templates = 1;
    }

    if (invoiceType.value.value != -1) {
      params['filters[type]'] = invoiceType.value.value;
    }
    if (category.value != -1) {
      params['filters[category]'] = category.value.value;
    }
    if (vendor.value.value != -1) {
      params['filters[vendor]'] = vendor.value.value;
    }
    if (paymentType.value.value != -1) {
      params['filters[payment_type]'] = paymentType.value.value;
    }
    if(startDate.value != ''){
      params['filters[start_date]'] = startDate.value;
    }
    if(endDate.value != ''){
      params['filters[end_date]'] = endDate.value;
    }

    params['filters[not_void]'] = 1;

    axios
      .get(`invoices`, {
        params,
      })
      .then(({ data }) => {
        documents.value = data.data.invoices;
        callback(data.data.invoices);
        totalDocuments.value = data.data.pagination.records_count;
      });
  };

  return {
	  components: {BCol, BFormGroup, BInputGroupPrepend, BInputGroup, BButton},
    fetchDocuments,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDocumentListTable,
    totalDocuments,
    invoiceType,
    category,
    vendor,
    paymentType,
    startDate,
    endDate,
    documents,
    documentFilter,
    refetchData,
  };
}
